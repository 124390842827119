@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    direction: rtl;
    max-width: 100%;
}
body.rtl {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    direction: rtl;
    max-width: 100%;
}
body.ltr {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    direction: ltr;
    max-width: 100%;
}

.Header {
    background-color: #2dc653;
    width: 100%;
    height: 180px;
}

.Header h1 {
    padding: 80px 300px;
    margin: 0;
    color: white;
    font-size: 35px;
}

.AltHeader h2 {
    margin-inline-start: 25%;
    padding: 30px 0 8px;
    position: relative;
    font-size: 30px;
}

.List {
    width: 40%;
    margin-inline-start: 29%;
    margin-bottom: 5%;
}
.ListEthics {
    width: 40%;
    margin-inline-start: 29%;
    margin-bottom: 5%;
}
.ListEthics h2 {
    font-size: 30px;
    font-weight: bolder;
    padding: 10px;
}
.ListEthics p {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}
.ListEthics li {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.List ul li:before {
    content: url('../src/img/x-mark-green-hi.png');
    position: relative;
    inset-inline-end: 5%;
}

.List li {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.Button {
    background-color: #2dc653;
    border: none;
    color: white;
    padding: 16px 18px;
    text-decoration: none;
    font-size: 16px;
    margin: 5px 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 2px;
    width: 30%;
}

.Button:hover {
    opacity: 0.75;
}

/* Navbar */
#language-picker-select {
    background-color: #25a244;
    margin-right: 25px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
}

.Navbar {
    text-align: center;
}

.LogoBar {
    display: flex;
}
.logo {
    width: 280px;
    length: 180px;
    padding: 25px;
    margin-left: auto;
    margin-right: auto;
}

nav {
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: inline-block;
    background: #25a244;
    text-align: center;
    height: 70px;
}

nav .NavbarList {
    margin: 0;
    padding: 0;
    list-style-type: none;

}

.NavbarList li {
    position: relative;
    display: inline-block;
}

.NavbarList li > a:only-child:after {
    content: '';
}

.NavbarList li a {
    padding: 20px;
    display: inline-block;
    color: white;
    text-decoration: none;
    transition: 300ms;
}

.NavbarList li a:hover {
    opacity: 0.5;
}

.NavbarDropdown {
    position: absolute;
    background: #25a244;
    width: 200px;
    padding: 0px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(50px);
    transition: opacity 0.4s ease, visibility 0s linear 0.4s, transform 0.4s ease;
}

.NavbarList li:hover .NavbarDropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
}

.NavbarDropdown li {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    nav .NavbarList {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #25a244;
        z-index: 999;
    }

    .NavbarDropdown {
        position: static;
    }

    .NavbarList li a {
        padding: 10px;
    }
}

/* Footer */

.Footer {
    background-color: #25a244;
    width: 100%;
    display: flex;
    flex-direction: column;

    overflow: hidden;
}

.Footer p {
    text-decoration: white;
}

.FooterList {
    list-style: none;
}

.About {
    padding: 0 20px;
    width: 25%;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 250px;
}

.About p {
    padding: 0 10px;

    font-size: 20px;
    color: white;
}

.Contact {
    padding: 20px;
    width: 20%;
    float: right;
}

.ContactList {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 20px 5px;
}

.ContactList li {
    padding: 20px 5px;
    color: white;
}

.IconList {
    display: flex;
}

.IconList div {
    padding: 5px 20px;
    font-size: 30px;
}

.Copyright {
    text-align: center;
    color: #ffffff;
}

@media (max-width: 768px) {
    .About,
    .Contact {
        width: 80%;
        float: none;
        margin: 0 auto;
        padding: 10px 0;
    }

    .IconList {
        justify-content: center;
    }
}

/* Add Volume */
.AddResearch {
    margin-right: 29%;
    width: 40%;
    max-width: 95%;
    margin-top: 100px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 5rem;
    border-radius: 0.5rem;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;
    padding-top: 30px;
}

.VolumeInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VolumeInfo input {
    width: 250px;
    padding: 7px;
    margin: 10px;
}

.Research {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Research div {
    padding: 10px;
    display: flex;
    align-items: center;
}

.Research input {
    width: 250px;
    padding: 7px;
    margin: 10px;
}

.AddImage {
    background-color: #2dc653;
    border: none;
    padding: 16px 18px;
    font-size: 16px;
    margin: 0px 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 2px;
    width: 80%;
    padding: 7px;
    margin: 10px;
    border-radius: 7px;
    max-width: 230px;
}

.ResearchInfo {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    max-width: 40%;
}

.ResearchInfo textarea {
    width: 500px;
    height: 80px;
    padding: 7px;
    margin: 10px;
}

@media (max-width: 768px) {
    .AddResearch {
        width: 80%;
        margin-right: 10%;
    }

    .ResearchInfo textarea {
        width: 90%;
    }
}

/* Login */
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Raleway, sans-serif;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    direction: ltr;
}

.screen {
    background: linear-gradient(90deg, #578128, #77af38);
    position: relative;
    height: 600px;
    width: 360px;
    box-shadow: 0px 0px 24px #578128;
}

.screen__content {
    z-index: 1;
    position: relative;
    height: 100%;
}

.screen__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
}

.screen__background__shape {
    transform: rotate(45deg);
    position: absolute;
}

.screen__background__shape1 {
    height: 520px;
    width: 520px;
    background: #fff;
    top: -50px;
    right: 120px;
    border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
    height: 220px;
    width: 220px;
    background: #578128;
    top: -172px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape3 {
    height: 540px;
    width: 190px;
    background: linear-gradient(270deg, #77af38, #9ee152);
    top: -24px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape4 {
    height: 400px;
    width: 200px;
    background: #77af38;
    top: 420px;
    right: 50px;
    border-radius: 60px;
}

.login {
    width: 320px;
    padding: 30px;
    padding-top: 90px;
}

.login__field {
    padding: 20px 0px;
    position: relative;
}

.login__icon {
    position: absolute;
    top: 30px;
    color: #578128;
}

.login__input {
    border: none;
    border-bottom: 2px solid #d3d3d3;
    background: none;
    padding: 10px;
    padding-left: 24px;
    font-weight: 700;
    width: 75%;
    transition: 0.2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
    outline: none;
    border-bottom-color: #578128;
}

.login__submit {
    background: #578128;
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 26px;
    border: 1px solid #77af38;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
    box-shadow: 0px 2px 2px #578128;
    cursor: pointer;
    transition: 0.2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
    border-color: #578128;
    outline: none;
}

.button__icon {
    font-size: 24px;
    margin-left: auto;
    color: #fff;
}

.social-login {
    position: absolute;
    height: 140px;
    width: 160px;
    text-align: center;
    bottom: 0px;
    right: 0px;
    color: #fff;
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-login__icon {
    padding: 20px 10px;
    color: #fff;
    text-decoration: none;
    text-shadow: 0px 0px 8px #77af38;
}

.social-login__icon:hover {
    text-shadow: 0px 0px 16px #fff;
}

.AddVoulumeInput {
    font-family: 'SF Pro';
    max-width: 190px;
    padding: 0.875rem;
    font-size: 1rem;
    border: 1.5px solid #000;
    border-radius: 0.5rem;
    outline: none;
    transition: ease 0.25s;
    text-align: center;
}

.SummaryInput {
    font-family: 'SF Pro';
    font-size: 1rem;
    border: 1.5px solid #000;
    border-radius: 0.5rem;

    width: 200px;
}

.AddVoulumeInput :focus {
    box-shadow: 5.5px 7px 0 black;
}

#hiddenContent {
    display: none;
}

.AddVolume #hiddenContent {
    display: block;
}
/*       Members of the program         */
.ag-format-container {
    width: 60%; /* Full width */
    margin: 0 auto;
}

.ag-courses_box {
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center items horizontally */
    flex-wrap: wrap; /* Allow items to wrap */
    padding: 50px 0; /* Top and bottom padding */
}

.ag-courses_item {
    flex: 0 1 calc(30% - 30px); /* Responsive size calculation */
    margin: 15px; /* Margin around each item */
    border: solid; /* Border for items */
    border-radius: 28px; /* Rounded corners */
    overflow: hidden; /* Hide overflow */
}

.ag-courses-item_link {
    display: block; /* Block display for link */
    padding: 30px 40px; /* Padding inside the link */
    background-color: #ffffff; /* Background color */
    position: relative; /* Relative positioning */
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
    text-decoration: none;
    color: #000000;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
}
.ag-courses-item_title {
    min-height: 87px;
    margin: 0 0 25px;

    overflow: hidden;

    font-weight: bold;
    font-size: 30px;
    color: #000000;

    z-index: 2;
    position: relative;
}
.ag-courses-item_date-box {
    font-size: 18px;
    color: #000000;

    z-index: 2;
    position: relative;
}
.ag-courses-item_date {
    font-weight: bold;
    color: #25a244;

    -webkit-transition: color 0.5s ease;
    -o-transition: color 0.5s ease;
    transition: color 0.5s ease;
}
.ag-courses-item_bg {
    height: 128px;
    width: 128px;
    background-color: #25a244;

    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;

    border-radius: 50%;

    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@media only screen and (max-width: 979px) {
    .ag-courses_item {
        -ms-flex-preferred-size: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
    }
    .ag-courses-item_title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .ag-format-container {
        width: 96%;
    }
}
@media only screen and (max-width: 639px) {
    .ag-courses_item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
    .ag-courses-item_title {
        min-height: 72px;
        line-height: 1;

        font-size: 24px;
    }
    .ag-courses-item_link {
        padding: 22px 40px;
    }
    .ag-courses-item_date-box {
        font-size: 16px;
    }
}
